/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
$white: #fff;
$black: #292929;
$primary: #292929;
$secondary: #6C7C8C;
$ternary: #D9D9D9;
$border: #eaeaea;
$gray: #F4F6F9;
$accent: #9900ff;
